<template>
    <div class="message-banner an-blog-InDown"
         :style="{backgroundImage: 'url(' + $store.getters.getBackground('messages')}">
        <div class="message-container">
            <div class="message-title mb-sm-6 animate__animated animate__fadeInDown">留言板</div>
            <div class="message-input-wrapper animate__animated animate__fadeInUp">
                <input v-model="messageContent" @click="show=true" @keyup.enter="addToList" type="text"
                       placeholder="说点什么吧....">
                <div @click="addToList" v-show="show"
                     class="message-input-btn ml-3  animate__animated animate__bounceInRight">
                    发送
                </div>
            </div>
        </div>

        <div class="barrage-container">
            <vue-baberrage
                    :barrageList="barrageList"
                    :loop="false"
            >
                <template v-slot:default="slotProps">
            <span :style="{'color':colorlists[Math.floor(Math.random() * colorlists.length)]}" class="barrage-items">
              {{ slotProps.item.msg }}
            </span>
                </template>
            </vue-baberrage>

        </div>
    </div>
</template>

<script>

  import {getMessageListApi, addMessageApi} from "@/network/interface"

  export default {
    name: "Message",
    data() {
      return {
        show: false,
        messageContent: "",
        barrageList: [],
        colorlists: [
          'hsl(42, 48%, 54%)',
          'hsl(138, 24%, 48%)',
          'rgb(200, 138, 131)',
          'rgb(84, 221, 226)',
          'rgb(178, 199, 168)',
          'rgb(16, 195, 195)',
          'hsl(0, 21%, 68%)',
          'rgb(226, 166, 198)',
          'hsl(278, 17%, 66%)',
          'rgb(153, 199, 235)',
          'blueviolet'
        ]
      }
    },
    mounted() {
      getMessageListApi().then(resp => {
        for (let i = 0; i < resp.data.length; i++) {
          this.barrageList.push({
            id: resp.data[i].id,
            msg: resp.data[i].blog_message,
            time: Math.floor(Math.random() * 10 + 5),
          })
        }
      })

    },
    methods: {
      addToList() {
        if (this.messageContent === "") {
          this.$toast({state: "error", message: "留言不能为空哦 ~"})
          return
        }
        addMessageApi({blog_message: this.messageContent}).then(resp => {
          this.$toast({state: "success", message: "留言成功~~"})
          console.log()
          this.barrageList.push({
            id: resp.data.id,
            msg: resp.data.blog_message,
            time: Math.floor(Math.random() * 10 + 5)
          });
          this.messageContent = ""
        })
      }
    }

  }
</script>

<style scoped>
    .message-banner {

        position: absolute;

        width: 100vw;
        height: 100vh;

        background: no-repeat center center;
        background-size: cover;
    }

    .message-container {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 30%;
        transform: translateX(-50%);
        width: 360px;

    }

    .message-title {
        font-size: 2.5rem;
        font-weight: 700;
        text-align: center;
        color: #eeeeee !important;
        animation-delay: 1s;

    }


    .message-input-wrapper {
        display: flex;
        justify-content: center;
        animation-delay: 1s;
    }

    .message-input-wrapper input {
        outline: none;
        height: 2.5rem;
        width: 80%;
        line-height: 2;
        padding: 0 1em;
        border: 2px solid #eeeeee;
        border-radius: 20px;
        color: #eeeeee !important;
    }

    .message-input-wrapper input::-webkit-input-placeholder {

        color: #eeeeee !important;
    }

    .message-input-btn {
        display: inline-block;
        border: 2px solid #eeeeee;
        border-radius: 20px;
        width: 20%;
        height: 2.5rem;
        line-height: 2;
        text-align: center;
        color: #eeeeee !important;
    }

    .barrage-container {

        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        height: calc(100% - 50px);
        width: 100%;
    }

    .barrage-items {
        font-size: 1.5rem;
        transition: all 3s;
    }

    @media screen and (max-width: 600px) {
        .message-input-wrapper {
            flex-direction: column;
            align-items: center;
        }

        .message-input-wrapper input {
            width: 90%;
            margin: 20px;
        }

        .message-input-btn {
            width: 30%;
            margin-left: 0 !important;
        }


    }
</style>
